<template>
  <div class="match">
    <div class="match__shield">
      <img
        src="/assets/shield.png"
        height="22"
        width="22"
        alt="local-shield"
      />
    </div>
    <div class="match__result">
      <span>{{ match.teams.local.name }} vs {{ match.teams.visitor.name }}</span>
      <span v-if="match.result">{{ match.result.local }} - {{ match.result.visitor }}</span>
      <span class="match__schedule">{{ match.info.date }}, {{ match.info.hour }}</span>
    </div>
    <div class="match__shield">
      <img
        src="/assets/shield.png"
        height="22"
        width="22"
        alt="visitor-shield"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Match",
  props: {
    match: Object,
  }
}
</script>

<style lang="sass" scoped>
@import './Match.sass'
</style>
