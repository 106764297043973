<template>
  <ion-grid class="newsfeedCard">
    <ion-row class="newsfeedCard__body">
      <ion-col>
        <div class="newsfeedCard__body__author">{{ news.getAuthor.getUsername }} comentó</div>
        {{ news.getMessage }}
      </ion-col>
      <ion-col v-if="showBookmark" size="1">
        <img
            src="/assets/bookmark.svg"
            height="22"
            width="22"
            alt="bookmark"
        />
      </ion-col>
    </ion-row>
    <ion-row class="newsfeedCard__date">
      <ion-col>
        {{ news.getFormattedCreatedAt }}
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonRow, IonGrid, IonCol } from "@ionic/vue";

export default {
  name: "NewsfeedCard",
  components: { IonRow, IonGrid, IonCol },
  props: {
    news: Object,
    showBookmark: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './NewsfeedCard.sass'
</style>
