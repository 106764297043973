<template>
  <ion-grid class="newsfeedCard">
    <ion-row class="newsfeedCard__body">
      <ion-col>
        ¡Jugador trapasado!
      </ion-col>
      <ion-col v-if="showBookmark" size="1">
        <img
            src="/assets/buy.svg"
            height="22"
            width="22"
            alt="bookmark"
        />
      </ion-col>
    </ion-row>
    <ion-row class="newsfeedCard__body">
      <ion-col>
        <strong>{{ news.getReferringUser.getUsername }}</strong> ha comprado a {{ news.getNotes.source }} al jugador {{ news.getNotes.playerName }}
        <span v-if="news.getNotes.amount > 0">
          por {{ longCurrency(news.getNotes.amount) }}
        </span>
      </ion-col>
    </ion-row>
    <ion-row class="newsfeedCard__date">
      <ion-col>
        {{ news.getFormattedCreatedAt }}
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonRow, IonGrid, IonCol } from "@ionic/vue";
import { longCurrency } from '@/domain/services/CurrencyFormatter'

export default {
  name: "PlayerTradedBoughtFeedCard",
  components: { IonRow, IonGrid, IonCol },
  props: {
    news: Object,
    showBookmark: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      longCurrency
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './NewsfeedCard.sass'
</style>
