<template>
  <div class="simpleLeagueStatus">
    <div class="simpleLeagueStatus__message">
      La nueva temporada empezará en
    </div>
    <div class="simpleLeagueStatus__time">
      <TimeTo :date="remaining" :refresh="1000" showSeconds />
    </div>
  </div>
</template>

<script>
import TimeTo from '@/components/TimeTo/TimeTo.vue'

export default {
  components: {
    TimeTo
  },
  props: {
    remaining: Date
  },
  setup() {
  },
}
</script>

<style lang="sass" scoped>
  @import './SimpleLeagueStatus.sass'
</style>