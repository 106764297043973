<template>
  <ion-page class="ion-page tabNews">
    <ion-header>
      <MenuHeaderWithInfo
        :showMenuButton="showItems"
        :showBackButton="!showItems"
        @onPressBackButton="toggleShowItems"
      />
    </ion-header>
    <ion-content>
      <div>
        <div class="tabNews__publish">
          <PublishNew
            @toggleShowItems="toggleShowItems"
            :showTextarea="!showItems"
            @createdNews="getNews(league)"
          />
        </div>
        <div class="tabNews__items" v-if="showItems">
          <router-link class="item" :to="{ name: 'team', hash: '#sponsor' }">
            <ChangeSponsor
              v-if="league && league.getSeason && league.getSeason.canChangeSponsor"
              :remaining="league.getSeason.getRegularStartAt"
            />
          </router-link>
          <div class="item">
            <InFinished
              v-if="inFinished"
              :remaining="finishedRemaining"
            />
          </div>
          <div class="item">
            <InDraft
              v-if="inDraft"
              :remaining="draftRemaining"
            />
          </div>
          <router-link class="item" to="mercado">
            <InBids
              v-if="inBids"
              :remaining="bidRemaining"
            />
          </router-link>
          <router-link class="item" to="mercado">
            <InOpenMarket
              v-if="inOpenMarket"
              :remaining="openMarketRemaining"
            />
          </router-link>
          <div class="tabNews__item item" v-for="news in mergedNews" :key="news.getId">
            <component
              :is="news.getCardName"
              :news="news"
              showBookmark
            />
          </div>
        </div>
      </div>
    </ion-content>
    <PopupModal
      :is-open="openSeasonStartModal"
      @closed="hideSeasonStartModal"
    >
      <div class="popupModal__standard">
        <div>
          Echa un vistazo por todas las pestañas de la aplicación para familiarizarte
          con las mecánicas y así descubrir tu club e incluso el de tus rivales!
          <br><br>
          Recuerda pasarte por tu plantilla y blindar a los jugadores que creas más
          importantes para tu club, mientras el mercado de fichajes continúe abierto
          podrás cambiar esos blindajes.
        </div>
        <div>
          <SmallButton text="Cerrar" @onClick="hideSeasonStartModal" />
        </div>
      </div>
    </PopupModal>
    <PopupModal
      :is-open="openSeasonEndModal"
      @closed="hideSeasonEndModal"
    >
      <div class="popupModal__standard">
        <div>
          Es hora de reestructurar tu equipo, analiza que te ha gustado y que no y actúa
          en consecuencia para perfeccionar la plantilla. Tienes la oportunidad de hacer
          hachazos a los jugadores no blindados de tus rivales (solo se puede hachazar a
          un mismo jugador una vez).
          <br><br>
          Recuerda que los jugadores TOP y TIT que no hayan
          cumplido sus objetivos, saldrán a las pujas para que el resto de managers pujen
          por ellos. Si nadie puja por ellos, volverán a su equipo de origen.
        </div>
        <div>
          <SmallButton text="Cerrar" @onClick="hideSeasonEndModal" />
        </div>
      </div>
    </PopupModal>
  </ion-page>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  IonPage,
  IonHeader,
  IonContent,
  menuController,
  onIonViewDidEnter
} from '@ionic/vue';
import RegularFeedCard from "@/components/Newsfeed/NewsfeedCard";
import WonBidFeedCard from "@/components/Newsfeed/WonBidFeedCard";
import PlayerSoldFeedCard from "@/components/Newsfeed/PlayerSoldFeedCard";
import PlayerBoughtFeedCard from "@/components/Newsfeed/PlayerBoughtFeedCard";
import UpdateResultFeedCard from "@/components/Newsfeed/UpdateResultFeedCard";
import ForceUpdateResultFeedCard from "@/components/Newsfeed/ForceUpdateResultFeedCard";
import PlayerTradedBoughtFeedCard from "@/components/Newsfeed/PlayerTradedBoughtFeedCard";
import SeasonFinishedFeedCard from "@/components/Newsfeed/SeasonFinishedFeedCard";
import DirectBuySoldFeedCard from "@/components/Newsfeed/DirectBuySoldFeedCard";
import Match from "@/components/Match/Match";
import PublishNew from "@/components/Newsfeed/PublishNew";
import MenuHeaderWithInfo from "@/components/Menu/MenuHeaderWithInfo";
import {
  STATUS_DRAFT as LEAGUE_STATUS_DRAFT,
  STATUS_BIDS as LEAGUE_STATUS_BIDS,
  STATUS_OPEN_MARKET as LEAGUE_STATUS_OPEN_MARKET,
  STATUS_FINISHED as LEAGUE_STATUS_FINISHED,
} from '@/domain/models/Season';
import InDraft from '@/components/LeagueStatusCard/InDraft.vue';
import InBids from '@/components/LeagueStatusCard/InBids.vue';
import InOpenMarket from '@/components/LeagueStatusCard/InOpenMarket.vue';
import InFinished from '@/components/LeagueStatusCard/InFinished';
import ChangeSponsor from '@/components/LeagueStatusCard/ChangeSponsor';
import PopupModal from '../../components/PopupModal/PopupModal.vue';
import SmallButton from '@/components/Button/SmallButton.vue'

export default  {
  name: 'TabNews',
  components: {
    PublishNew,
    IonContent,
    IonHeader,
    IonPage,
    Match,
    MenuHeaderWithInfo,
    RegularFeedCard,
    WonBidFeedCard,
    PlayerSoldFeedCard,
    PlayerBoughtFeedCard,
    UpdateResultFeedCard,
    ForceUpdateResultFeedCard,
    PlayerTradedBoughtFeedCard,
    SeasonFinishedFeedCard,
    DirectBuySoldFeedCard,
    InDraft,
    InBids,
    InOpenMarket,
    InFinished,
    ChangeSponsor,
    PopupModal,
    SmallButton
  },
  setup() {
    const store = useStore()

    const openSeasonStartModal = ref(false)
    const openSeasonEndModal = ref(false)
    const showItems = ref(true)
    const mergedNews = ref([])

    const toggleShowItems = () => {
      showItems.value = !showItems.value
    }
    const openMenu = () => {
      menuController.open()
    }

    const league = computed(() => {
      const league = store.getters.getCurrentLeague
      if (league.getId) {
        getNews(league)
      }

      return league
    })

    const inDraft = computed(() => {
      if (!league.value || !league.value.getSeason) {
        return false
      }
      return league.value.getSeason.getStatus == LEAGUE_STATUS_DRAFT
    })
    const inBids = computed(() => {
      if (!league.value || !league.value.getSeason) {
        return false
      }
      return league.value.getSeason.getStatus == LEAGUE_STATUS_BIDS
    })
    const inOpenMarket = computed(() => {
      if (!league.value || !league.value.getSeason) {
        return false
      }
      return league.value.getSeason.getStatus == LEAGUE_STATUS_OPEN_MARKET
    })
    const inFinished = computed(() => {
      if (!league.value || !league.value.getSeason) {
        return false
      }
      return league.value.getSeason.getStatus == LEAGUE_STATUS_FINISHED
    })

    const draftRemaining = computed(() => league.value.getSeason.getBidStartAt)
    const bidRemaining = computed(() => league.value.getSeason.getOpenMarketStartAt)
    const openMarketRemaining = computed(() => league.value.getSeason.getRegularStartAt)
    const finishedRemaining = computed(() => league.value.getSeason.getRestartedAt)

    const compare = function (NewsA, NewsB) {
      if (NewsA.getCreatedAt.getTime() < NewsB.getCreatedAt.getTime()) {
        return 1
      }
      if (NewsA.getCreatedAt.getTime() > NewsB.getCreatedAt.getTime()) {
        return -1
      }
      return 0
    }

    const getNews = (league) => {
      const data = {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
      }
      store.dispatch('getNews', data)
      .then(news => {
        const data = {
          leagueId: league.getId,
          seasonId: league.getSeason.getId
        }
        store.dispatch('getBotNews', data)
          .then (botNews => {
            mergedNews.value = news.concat(botNews).sort(compare)
          })
      })
    }

    const hideSeasonStartModal = () => {
      openSeasonStartModal.value = false
      store.commit("seasonStartPopupShown")
    }

    const hideSeasonEndModal = () => {
      openSeasonEndModal.value = false
      store.commit("seasonEndPopupShown")
    }

    onIonViewDidEnter(() => {
      openSeasonStartModal.value = !store.getters.getSeasonStartPopupShown
      openSeasonEndModal.value = inFinished.value && !store.getters.getSeasonEndPopupShown
    })


    return {
      league,
      showItems,
      mergedNews,
      inDraft,
      inBids,
      inOpenMarket,
      inFinished,
      openSeasonStartModal,
      openSeasonEndModal,

      getNews,
      draftRemaining,
      bidRemaining,
      openMarketRemaining,
      finishedRemaining,
      toggleShowItems,
      openMenu,
      hideSeasonStartModal,
      hideSeasonEndModal
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './TabNews.sass'
</style>
