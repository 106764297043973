<template>
  <div class="publishNew">
    <div class="publishNew__card">
      <div class="publishNew__card--small" @click="toggleTextarea">
        <InitialsAvatar>{{ user.getInitials }}</InitialsAvatar>
        <div class="publishNew__label" v-if="!showTextarea">
          <span>¿Qué estas pensando?</span>
        </div>
      </div>
      <div class="publishNew__card--big" v-if="showTextarea">
        <ion-textarea placeholder="¿Qué estas pensando?" rows="15" v-model="publishText"/>
      </div>
    </div>
    <div class="publishNew__buttons" v-if="showTextarea">
      <PrimaryButton v-if="enablePublish" text="Publicar" @onClick="handlePublish"/>
      <SecondaryButton v-if="!enablePublish" text="Publicar"/>
    </div>
    <PopupModal
      :is-open="showMessageSendModal"
      @closed="closeMessageSendModal()"
      extraClass="small"
    >
      <div class="messageSent">
        <div class="messageSent__title">
          Tu mensaje fue enviado correctamente
        </div>
        <div class="messageSent__button">
          <SmallButton text="Cerrar" @onClick="closeMessageSendModal()" />
        </div>
      </div>
    </PopupModal>
  </div>
</template>

<script>
import {
  IonTextarea,
} from "@ionic/vue"
import PrimaryButton from "@/components/Button/PrimaryButton"
import SecondaryButton from "@/components/Button/SecondaryButton"
import InitialsAvatar from "@/components/InitialsAvatar/InitialsAvatar"
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import PopupModal from '@/components/PopupModal/PopupModal.vue'
import SmallButton from '@/components/Button/SmallButton.vue'

export default {
  name: "PublishNew",
  components: {
    IonTextarea,
    PrimaryButton,
    SecondaryButton,
    InitialsAvatar,
    PopupModal,
    SmallButton,
  },
  props: {
    avatar: String,
    showTextarea: Boolean
  },
  emits: ['toggleShowItems', 'createdNews'],
  setup(_, { emit }) {
    const store = useStore()
    const user = store.getters.getLoggedUser
    const publishText = ref('')
    const showMessageSendModal = ref(false)

    const enablePublish = computed(() => '' !== publishText.value)

    const toggleTextarea = () => {
      emit('toggleShowItems')
    }

    const handlePublish = () => {
      store.commit('showLockScreen')
      const league = store.getters.getCurrentLeague
      const data = {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        message: publishText.value
      }
      store.dispatch('createNews', data)
        .then(() => {
          store.commit('hideLockScreen')
          emit('toggleShowItems')
          showMessageSendModal.value = true
          emit('createdNews')
          publishText.value = ''
        })
    }

    const closeMessageSendModal = () => {
      showMessageSendModal.value = false
    }


    return {
      publishText,
      user,
      showMessageSendModal,

      enablePublish,
      toggleTextarea,
      handlePublish,
      closeMessageSendModal,
    }
  }
}
</script>

<style lang="sass" scoped>
  @import "./PublishNew.sass"
</style>
