<template>
  <ion-grid class="newsfeedCard">
    <ion-row class="newsfeedCard__body">
      <ion-col>
        <div class="newsfeedCard__body--title">
          Temporada <br>Finalizada
        </div>
        <div class="newsfeedCard__body--list">
          <div v-if="news.getNotes[0]">1º {{ news.getNotes[0] }}</div>
          <div v-if="news.getNotes[1]">2º {{ news.getNotes[1] }}</div>
          <div v-if="news.getNotes[2]">3º {{ news.getNotes[2] }}</div>
        </div>
      </ion-col>
      <ion-col />
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonRow, IonGrid, IonCol } from "@ionic/vue";

export default {
  name: "SeasonFinishedFeedCard",
  components: {
    IonRow,
    IonGrid,
    IonCol,
  },
  props: {
    news: Object
  },
  setup() {
  }
}
</script>

<style lang="sass" scoped>
  @import './SeasonFinishedFeedCard.sass'
</style>
