<template>
  <div class="leagueStatus">
    <div class="leagueStatus__message">
      Es hora de ajustar plantilla y sanear tus cuentas
    </div>
    <div class="leagueStatus__panel">
      <SmallBadge class="leagueStatus__panel__badge">Mercado libre 🛒</SmallBadge>
      <div class="leagueStatus__panel__countdown">
        Finaliza en
        <div class="leagueStatus__panel__countdown__remining">
          <TimeTo :date="remaining" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallBadge from '../Badge/SmallBadge.vue'
import TimeTo from '@/components/TimeTo/TimeTo.vue'

export default {
  components: {
    SmallBadge,
    TimeTo
  },
  props: {
    remaining: Date
  },
  setup() {

  },
}
</script>

<style lang="sass" scoped>
  @import './LeagueStatus.sass'
</style>