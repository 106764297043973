<template>
  <ion-grid class="updateResultCard" @click="showResult(news.getNotes.resultId)">
    <ResultCard
      :id="news.getNotes.resultId"
      homeAvatar="/assets/logo.png"
      :homeName="news.getNotes.homeTeam"
      :homeResult="news.getNotes.homeResult"
      visitorAvatar="/assets/logo.png"
      :visitorName="news.getNotes.visitorTeam"
      :visitorResult="news.getNotes.visitorResult"
      :date="convertedDate"
      hasResults
      :isLocal="false"
    />
  </ion-grid>
  <FullModal
    :openedModal="showResultModal"
    @onPressBackButton="hideResultModal"
  >
    <ShowResult :result="result" @saved="close()" />
  </FullModal>
</template>

<script>
import ShowResult from '@/components/ResultPopups/ShowResult.vue';
import { ref } from '@vue/reactivity';
import FullModal from '../FullModal/FullModal.vue';
import { useStore } from 'vuex';
import ResultCard from '@/components/ResultCard/ResultCard.vue';
import { shortDate } from '@/domain/services/DateFormatter';
import { IonGrid } from "@ionic/vue";

export default {
  name: "UpdateResultFeedCard",
  components: {
    ShowResult,
    IonGrid,
    FullModal,
    ResultCard,
  },
  props: {
    news: Object,
    showBookmark: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()
    const showResultModal = ref(false)
    const result = ref()

    const convertedDate = shortDate(new Date(props.news.getNotes.date))

    const showResult = (resultId) => {
      const data = {
        leagueId: store.getters.getCurrentLeague.getId,
        seasonId: store.getters.getCurrentLeague.getSeason.getId,
        resultId: resultId
      }
      store.dispatch('getResult', data)
        .then(res => {
          result.value = res
          showResultModal.value = true
        })
    }

    const hideResultModal = () => {
      showResultModal.value = false
    }

    const close = () => {
      setTimeout(() => {
        hideResultModal();
      }, 0)
    }

    return {
      showResultModal,
      result,
      convertedDate,

      hideResultModal,
      close,
      showResult,
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './UpdateResultCard.sass'
</style>
